import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from './common/templates/loader/templates/loader/loader.service';
import { LoaderConstants } from './common/templates/loader/modules/loader.constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false,
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit{
    title = 'mc-ob-test-app-merchant';
    buttonLoader = LoaderConstants.buttonLoader;

    constructor(public readonly loaderService: LoaderService,
      private readonly cdr: ChangeDetectorRef
    ){}

    ngOnInit() {
      // Subscribe to the isLoading$ observable and manually trigger change detection
      // when the value changes
      this.loaderService.isLoading$.subscribe(() => {
          this.cdr.detectChanges();
      });
  }
}
