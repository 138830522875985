/* eslint-disable  @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiRoutes } from '../../routes/api/api-routes';
import { navigationRoutes } from '../../routes/navigation/navigation-routes';
import { Status } from '../../models/response';
import { environment } from '../../../../environments/environment';
import { PartnerProgramAuthServiceService } from '../../../partner-program/modules/services/partner-program-auth-service.service';
import { AdminAuthService } from '../../../admin/modules/services/admin-auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        public partnerProgramAuthServiceService: PartnerProgramAuthServiceService,
        public adminAuthService: AdminAuthService,
        public router: Router,
        public activatedRoute: ActivatedRoute
    ) {}

    // Adds necessary headers to the request
    private updateHeaders(request: {
        clone: (arg0: {
            setHeaders:
                | { 'Content-Type': string; Authorization: string; currencyCode: string; languageCode: string }
                | { 'Content-Type': string };
        }) => any;
    }) {
        let getToken;
        if (localStorage.getItem('login-flow') === 'admin') {
            getToken = this.adminAuthService.getToken();
        } else {
            getToken = this.partnerProgramAuthServiceService.getToken();
        }
        if (getToken) {
            return request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getToken}`,
                },
            });
        } else {
            return request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                },
            });
        }
    }

    // Handles error responses from API requests
    private handleError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        if (error.status === 401) {
            sessionStorage.clear();
            localStorage.clear();
            if (request.url.endsWith(apiRoutes.partnerProgramLogin)) {
                this.router.navigate([navigationRoutes.navigateToPartnerProgramLogin]);
            }
        }

        let errorMessage = {
            status: Status.NOK,
            message: 'Something went wrong. Please try again later.',
        };

        if (error.error) {
            errorMessage = error?.error;
        }

        if (error.error instanceof ProgressEvent) {
            // Handle server unavailable error
            errorMessage = {
                status: Status.NOK,
                message: 'Server Unavailable. Please try again later.',
            };

            // Update UI based on server unavailable error
            const serverUnavailableDiv = window.document.getElementById('server-unavailable');
            if (serverUnavailableDiv) {
                serverUnavailableDiv.innerHTML = errorMessage.message;
            }

            const refreshButtonDiv = window.document.getElementById('refresh-button');
            if (refreshButtonDiv) {
                refreshButtonDiv.classList.remove('d-none');
            }
        } else if (error.error?.message) {
            // Use error message from response if available
            errorMessage.message = error.error.message;
        }

        return throwError(() => errorMessage);
    }

    // Intercepts HTTP requests and handles token refresh and error responses
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.endsWith(apiRoutes.checkLoginStatus)) {
            // For certain requests, update headers and proceed
            request = this.updateHeaders(request);
            return next.handle(request);
        } else if (request.url.startsWith(environment.translationBucketUrl)) {
            return next.handle(request);
        }

        // Update headers for the request
        request = this.updateHeaders(request);

        // Handle request and response
        return next.handle(request).pipe(
            map((event) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => this.handleError(request, next, error))
        );
    }
}
