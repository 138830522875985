import { Component, Input } from '@angular/core';
import { LoaderService } from './loader.service';
import { LoaderConstants } from '../../modules/loader.constants';

@Component({
    selector: 'app-loader',
    standalone: false,
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
    buttonLoader = LoaderConstants.buttonLoader;
    constructor(public loaderService: LoaderService) {}
    @Input() loaderType: string | undefined;
}
