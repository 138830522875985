<div [ngClass]="{ 'loader-overlay': loaderType !== buttonLoader }" *ngIf="loaderService.isLoading$ | async">
    <div [ngSwitch]="loaderService.loaderType">
        <!-- Circle Loader -->
        <div class="lds-circle" *ngSwitchCase="'circle'"></div>
        <!-- Dual Ring Loader -->
        <div class="lds-dual-ring" *ngSwitchCase="'dual-ring'"></div>
        <!-- button-loader Loader -->
        <div class="lds-button-loader" *ngSwitchCase="'button-loader'">
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- Heart Loader -->
        <div class="lds-heart" *ngSwitchCase="'heart'">
            <div></div>
        </div>
        <!-- Ring Loader -->
        <div class="lds-ring" *ngSwitchCase="'ring'">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- Roller Loader -->
        <div class="lds-roller" *ngSwitchCase="'roller'">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- Default Loader -->
        <div class="lds-default" *ngSwitchCase="'default'">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- Ellipsis Loader -->
        <div class="lds-ellipsis" *ngSwitchCase="'ellipsis'">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- Grid Loader -->
        <div class="lds-grid" *ngSwitchCase="'grid'">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <!-- Hourglass Loader -->
        <div class="lds-hourglass" *ngSwitchCase="'hourglass'"></div>
        <!-- Ripple Loader -->
        <div class="lds-ripple" *ngSwitchCase="'ripple'">
            <div></div>
            <div></div>
        </div>
        <!-- Spinner Loader -->
        <div class="lds-spinner" *ngSwitchCase="'spinner'">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
