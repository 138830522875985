import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'partner-program',
        redirectTo: 'openbankingtestapp',
        pathMatch: 'full',
    },
    {
        path: '',
        redirectTo: 'openbankingtestapp',
        pathMatch: 'full',
    },
    {
        path: 'openbankingtestapp',
        loadChildren: () => import('./partner-program/partner-program.module').then((m) => m.PartnerProgramModule),
        data: { showDashboard: false },
        title: 'Open Banking Test App',
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        data: { showDashboard: false },
        title: 'Admin',
    },
    {
        path: 'finicity/bank/connect/callback',
        loadChildren: () =>
            import('./finicity/templates/finicity-callback/finicity-callback.module').then((m) => m.FinicityCallbackModule),
        data: { showDashboard: false },
        title: 'Linking your Bank Account | Moovet',
    },
    {
        path: '**',
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule),
        data: { showDashboard: false },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
