import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './common/modules/material/material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './common/modules/translator/translator.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './common/interceptors/token/token.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { OtpVerificationModule } from './common/templates/otp-verification/otp-verification.module';
import { LoaderModule } from './common/templates/loader/templates/loader/loader.module';
import { LoaderService } from './common/templates/loader/templates/loader/loader.service';
import { AdminMainLayoutModule } from './admin/templates/layouts/admin-main-layout/admin-main-layout.module';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({
    declarations: [AppComponent],
    imports: [
        AdminMainLayoutModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        OtpVerificationModule,
        LoaderModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en-US',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: JWT_OPTIONS,
            useValue: JWT_OPTIONS,
        },
        JwtHelperService,
        LoaderService,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
